:root {
  /** Card **/
  --card-size-h: 302px;
  --card-size-xs-w: 144px;
  --card-size-xs-h: var(--card-size-h);
  --card-size-s-w: 164px;
  --card-size-s-h: var(--card-size-h);
  --card-size-m-w: 328px;
  --card-size-m-h: var(--card-size-h);
  --card-size-l-w: 492px;
  --card-size-l-h: var(--card-size-h);
  --card-size-xl-w: 865px;
  --card-size-xl-h: var(--card-size-h);

  /** Flat Card **/
  --card-size-flat-m-w: 328px;
  --card-size-flat-m-h: 151px;
  --card-size-flat-xl-w: 865px;
  --card-size-flat-xl-h: auto;
}
