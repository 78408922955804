/** Tailwind must be the first import **/
@import "settings/_settings.tailwind";

@import "@splidejs/splide/dist/css/themes/splide-default.min.css";
@import "react-loading-skeleton/dist/skeleton.css";

@import "settings/_settings.cards";
@import "settings/_settings.colors";
@import "settings/_settings.common";
@import "settings/_settings.fonts";

@layer base {
  body {
    @apply u-body u-body--m;
  }

  button {
    @apply u-actions u-actions--link;
  }

  h1,
  h2,
  h3,
  h4 {
    @apply u-headline;
  }

  h1 {
    @apply u-headline--h1;
  }

  h2 {
    @apply u-headline--h2;
  }

  h3 {
    @apply u-headline--h3;
  }

  h4 {
    @apply u-headline--h4;
  }

  #redsys-hosted-pay-button {
    border: 0 !important;
    background-color: transparent !important;
  }

  #card-form {
    margin-left: -420px;
  }

  @media (max-width: 1180px) {
    #card-form {
      margin-top: 20px;
      margin-left: 0;
    }
  }

  @media (max-width: 600px) {
    #card-form {
      height: 400px;
    }
  }

  #category-search .ais-SearchBox-submitIcon {
    display: none;
  }

  #__next {
    @apply relative flex min-h-screen flex-col bg-primary-50;
  }
}

#cookiebanner input {
  appearance: auto !important;
}

.order-line-methods {
  @apply md:grid-cols-6 !important;
}

.date-fokus label {
  font-size: 10px;
  top: 0.25rem;
  line-height: 16px;
  letter-spacing: 0.3px;
}

.left-triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 0px solid transparent;
  border-top: 15px solid currentColor;
}

.right-triangle {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid currentColor;
}
