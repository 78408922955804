:root {
  /** Color primary **/
  --tw-color-primary-50: rgb(248 247 244); /** #f8f7f4 **/
  --tw-color-primary-100: rgb(236 231 225); /** #ece7e1 **/
  --tw-color-primary-200: rgb(216 211 206); /** #d8d3ce **/
  --tw-color-primary-300: rgb(190 183 175); /** #beb7af **/
  --tw-color-primary-400: rgb(163 152 143); /** #a3988f **/
  --tw-color-primary-500: rgb(136 127 120); /** #887f78 **/
  --tw-color-primary-600: rgb(110 103 98); /** #6e6762 **/
  --tw-color-primary-700: rgb(76 73 70); /** #4c4946 **/
  --tw-color-primary-800: rgb(57 55 54); /** #393736 **/
  --tw-color-primary-900: rgb(38 38 38); /** #262626 **/

  /** Color secondary **/
  --tw-color-secondary-50: rgb(255 241 230); /** #fff1e6 **/
  --tw-color-secondary-100: rgb(255 230 210); /** #ffe6d2 **/
  --tw-color-secondary-200: rgb(255 205 165); /** #ffcda5 **/
  --tw-color-secondary-300: rgb(255 180 121); /** #ffb479 **/
  --tw-color-secondary-400: rgb(254 155 77); /** #fe9b4d **/
  --tw-color-secondary-500: rgb(246 130 23); /** #f68217 **/
  --tw-color-secondary-600: rgb(199 105 19); /** #c76913 **/
  --tw-color-secondary-700: rgb(138 82 39); /** #8a5227 **/
  --tw-color-secondary-800: rgb(88 60 40); /** #583c28 **/
  --tw-color-secondary-900: rgb(70 51 38); /** #463326 **/

  /** Color tertiary **/
  --tw-color-tertiary-50: rgb(249 242 255); /** #f9f2ff **/
  --tw-color-tertiary-100: rgb(245 231 255); /** #f5e7ff **/
  --tw-color-tertiary-200: rgb(234 208 255); /** #ead0ff **/
  --tw-color-tertiary-300: rgb(223 185 254); /** #dfb9fe **/
  --tw-color-tertiary-400: rgb(210 162 253); /** #d2a2fd **/
  --tw-color-tertiary-500: rgb(130 23 246); /** #8217f6 **/
  --tw-color-tertiary-600: rgb(109 38 189); /** #6d26bd **/
  --tw-color-tertiary-700: rgb(87 43 135); /** #572b87 **/
  --tw-color-tertiary-800: rgb(64 42 85); /** #402a55 **/
  --tw-color-tertiary-900: rgb(49 40 56); /** #312838 **/

  /** Color background **/
  --tw-color-bg-primary: rgb(248 247 244); /** #f8f7f4 **/
  --tw-color-bg-secondary: rgb(255 255 255); /** #ffffff **/
  --tw-color-bg-tertiary: rgb(38 38 38); /** #262626 **/
  --tw-color-bg-action: rgb(246 130 23); /** #f68217 **/

  /** Color status **/
  --tw-color-status-success: rgb(23 185 130); /** #17b982 **/
  --tw-color-status-error: rgb(211 41 41); /** #d32929 **/
  --tw-color-status-progress: rgb(130 23 246); /** #8217f6 **/

  /** Color typos **/
  --tw-color-typo-primary: rgb(38 38 38); /** #262626 **/
  --tw-color-typo-secondary: rgb(110 103 98); /** #6e6762 **/
  --tw-color-typo-tertiary: rgb(163 152 143); /** #a3988f **/
  --tw-color-typo-alternative: rgb(255 255 255); /** #ffffff **/
}
